<template>
  <input
    :type="email ? 'email' : 'text'"
    class="form-control"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    modelValue: String,
    email: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style scoped>
input {
  color: #000;
  border: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  border-bottom: 1px solid #000 !important;
  border-radius: 0px !important;
}
input:focus {
  box-shadow: none !important;
}
</style>