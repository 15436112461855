import request from './request'

export function login(data) {
  return request({
    url: 'login',
    method: 'post',
    data
  })
}

export function register(data) {
  return request({
    url: 'register',
    method: 'post',
    data
  })
}

export function forgotPwd(data) {
  return request({
    url: 'forgot-password',
    method: 'post',
    data
  })
}

export function resetPwd(data) {
  return request({
    url: 'password-reset',
    method: 'post',
    data
  })
}

export function updatePassword(data) {
  return request({
    url: 'account/password',
    method: 'put',
    data
  })
}

export function updateProfile(data) {
  return request({
    url: 'account',
    method: 'put',
    data
  })
}

export function getInfo() {
  return request({
    url: 'account',
    method: 'get'
  })
}


export function logout() {
  return request({
    url: 'logout',
    method: 'post'
  })
}

export function startEmailVerification(data) {
  return request({
    url: 'user-verification/start',
    method: 'post',
    data
  })
}

export function emailVerification(data) {
  return request({
    url: 'user-verification/verify',
    method: 'post',
    data
  })
}