<template>
  <el-card style="margin-bottom: 20px">
    <template #header class="clearfix">
      <span>A propos</span>
    </template>

    <div class="user-profile">
      <div class="box-center">
        <pan-thumb :image="avatar" :height="'100px'" :width="'100px'">
          <div>Hello</div>
        </pan-thumb>
      </div>
      <div class="box-center">
        <div class="user-name text-center">{{ user.name }}</div>
        <div class="user-role text-center text-muted">{{ user.email }}</div>
        <div class="user-role text-center text-muted">{{ user.phone }}</div>
      </div>
    </div>

    <div class="user-bio">
      <div class="user-education user-bio-section" v-if="user?.active_user_offers">
        <div class="user-bio-section-header">
          <svg-icon icon-class="education" /><span>Abonnement actif</span>
        </div>
        <div class="user-bio-section-body">
          <div class="text-muted">
            <span v-if="!user?.active_user_offers[0]?.offer?.name"> Pas d'abonnement</span>
            <span v-else>
            {{ user?.active_user_offers[0]?.offer?.name }} |
            {{ user?.active_user_offers[0]?.offer?.description }} | 
            {{ user?.active_user_offers[0]?.offer?.price +  ' F CFA' }}
            </span>
          </div>
        </div>
      </div>

      <!-- <div class="user-skills user-bio-section">
        <div class="user-bio-section-header"><svg-icon icon-class="skill" /><span>Skills</span></div>
        <div class="user-bio-section-body">
          <div class="progress-item">
            <span>Vue</span>
            <el-progress :percentage="70" />
          </div>
          <div class="progress-item">
            <span>JavaScript</span>
            <el-progress :percentage="18" />
          </div>
          <div class="progress-item">
            <span>Css</span>
            <el-progress :percentage="12" />
          </div>
          <div class="progress-item">
            <span>ESLint</span>
            <el-progress :percentage="100" status="success" />
          </div>
        </div>
      </div> -->

    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: "",
          email: "",
          role: "",
        };
      },
    },
  },
  data: () => ({
    avatar:
      "https://cdn.dribbble.com/users/304574/screenshots/6222816/male-user-placeholder.png",
  }),
};
</script>

<style lang="scss" scoped>
.box-center {
  margin: 0 auto;
  display: table;
}

.text-muted {
  color: #777;
}

.user-profile {
  .user-name {
    font-weight: bold;
  }

  .box-center {
    padding-top: 10px;
  }

  .user-role {
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
  }

  .box-social {
    padding-top: 30px;

    .el-table {
      border-top: 1px solid #dfe6ec;
    }
  }

  .user-follow {
    padding-top: 20px;
  }
}

.user-bio {
  margin-top: 20px;
  color: #606266;

  span {
    padding-left: 4px;
  }

  .user-bio-section {
    font-size: 14px;
    padding: 15px 0;

    .user-bio-section-header {
      border-bottom: 1px solid #dfe6ec;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-weight: bold;
    }
  }
}
</style>
