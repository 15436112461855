import { login, logout, getInfo, startEmailVerification } from "@/http/auth";
import { getToken, setToken, removeToken } from "@/helpers/auth";
import { resetRouter } from "@/router";

const state = () => ({
  access_token: getToken(),
  user: null,
  roles: [],
  loginStatus: "",
});

const mutations = {
  SET_ACCESS_TOKEN: (state, token) => {
    setToken(token);
    state.access_token = token;
  },
  SET_USER: (state, user) => {
    state.user = user;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_LOGIN_STATUS: (state, loginStatus) => {
    state.loginStatus = loginStatus;
  },
};

const actions = {
  // user login
  login(context, payload) {
    const { email, password } = payload;
    return new Promise((resolve, reject) => {
      login({ email: email.trim(), password: password })
        .then((response) => {
          if (response.user.role == "admin") {
            reject("Vous n'etes pas autorisé à vous connecter");
          } else {
            const { token } = response;
            context.commit("SET_ACCESS_TOKEN", token);
            context.commit("SET_ROLES", [response.user.role]);
            context.commit("SET_USER", response.user);
            context.commit("SET_LOGIN_STATUS", "success");
            resolve();
          }
        })
        .catch(async (error) => {
          context.commit("SET_LOGIN_STATUS", "failed");
          if (error.response.data?.user?.is_verified === 0) {
            await startEmailVerification({ email }).then(() => {
              const data = window.btoa(email)
              location.href = "/#/verification-email/" + data;
            });
          }
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((response) => {
          if (!response) {
            reject("Verification failed, please Login again.");
          } else {
            const { role } = response;

            // roles must be a non-empty array
            if (!role || role.length <= 0) {
              reject("getInfo: roles must be a non-null array!");
            }
            Array.isArray(role)
              ? commit("SET_ROLES", role)
              : commit("SET_ROLES", Array(role));
            commit("SET_USER", response);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve) => {
      logout().then(() => {
        resolve();
      });
      commit("SET_ROLES", []);
      removeToken();
      resetRouter();
      location.reload();
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_ROLES", []);
      removeToken();
      location.reload();
      resolve();
    });
  },
};

const getters = {
  getLoginStatus(state) {
    return state.loginStatus;
  },
  roles(state) {
    return state.roles;
  },
  isUserRole: (state) => (value) => {
    const result = state.roles.find((val) => val === value);
    if (result) {
      return true;
    } else {
      return false;
    }
  },
  token(state) {
    return state.access_token;
  },
  profile(state) {
    return state.user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
