<script>
import { Icon } from "@iconify/vue";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  components: {
    Icon,
  },
  props: {
    minimal: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters["user/profile"]);
    const handleLogout = () => {
      store.dispatch("user/resetToken");
    };
    return {
      user,
      handleLogout,
    };
  },
};
</script>

<template>
  <header class="navbar">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div v-if="minimal" class="d-flex align-items-center">
        <a href="https://opencontrat.com">
          <img alt="logo" src="@/assets/logo.png" height="50" />
        </a>
        <div class="ms-4 d-flex align-items-center">
          <a
            href="https://opencontrat.com/contratheque"
            class="mx-2 nav_link"
          >
            Contrathèque
          </a>
          <router-link to="/" class="mx-2 nav_link">
            Contrats achetés
          </router-link>
          <router-link
            v-if="
              user?.active_user_offers &&
              user?.active_user_offers[0]?.offer?.name == 'Partner'
            "
            to="/mescontrats"
            class="mx-2 nav_link"
          >
            Mes contrats
          </router-link>
          <span class="ms-5 pointer nav_link" @click="handleLogout()">
            Déconnexion
          </span>
        </div>
      </div>
      <label v-else for="sidebar-btn-check">
        <Icon
          class="pointer ms-1"
          icon="ant-design:menu-fold-outlined"
          width="25"
          height="25"
        />
      </label>

      <div class="d-flex justify-content-between align-items-center me-4">
        <span class="pointer">
          <Icon icon="clarity:notification-line" width="30" height="30" />
        </span>
        <p class="mx-3 m-0 divider"></p>
        <div class="d-flex justify-content-start align-items-center">
          <Icon icon="bxs:user-circle" width="35" height="35" class="" />
          <div class="size-small">
            <router-link to="profil" class="d-block">
              {{ user?.name }}
            </router-link>
            <small v-if="user?.active_user_offers?.length <= 0" class="d-block role">
              Pas d'offre
            </small>
            <small v-else class="d-block role">
              <span>Offre</span> 
              {{ user?.active_user_offers[0]?.offer?.name }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
.navbar {
  height: 60px;
  width: 100%;
  background-color: #fcfcfc;
}
.img_container {
  width: 35px;
  height: 35px;
  border-radius: 30px;
  border: 1.5px solid #dfe0eb;
}
.role {
  color: #2696be;
}
.divider {
  min-width: 1px;
  height: 20px;
  background: #dfe0eb;
}
.size-small {
  font-size: 13px;
}

.navbar .router-link-active.router-link-exact-active {
  color: #dc3545;
}
.nav_link {
  font-size: 0.875rem;
}
</style>
