<script>
import { Icon } from "@iconify/vue";
import { useStore } from "vuex";
export default {
  components: {
    Icon,
  },
  setup() {
    const store = useStore();
    const handleLogout = () => {
      store.dispatch("user/resetToken");
    };

    return {
      handleLogout,
    };
  },
};
</script>

<template>
  <input
    type="checkbox"
    class="sidebar-btn-check"
    id="sidebar-btn-check"
    autocomplete="off"
  />
  <div class="sidebar">
    <label for="sidebar-btn-check" class="close-sidebar pointer">Fermer</label>
    <div class="p-3">
      <a href="https://opencontrat.com">
        <img alt="logo" src="@/assets/logo.png" width="150" />
      </a>
    </div>
    <h5 class="ms-2 fw-bold menu">Menu</h5>
    <div class="ps-3 mt-4">
      <router-link
        to="/"
        class="d-flex justify-content-start align-items-center mb-3"
      >
        <Icon icon="teenyicons:contract-outline" />
        <span class="ms-2">Mes contrats</span>
      </router-link>

      <a
        href="https://opencontrat.com/contratheque"
        target="_blank"
        class="d-flex justify-content-start align-items-center mb-3"
      >
        <Icon icon="la:file-contract" />
        <span class="ms-2"
          >Contrathèque<Icon icon="ci:external-link" width="12" height="12"
        /></span>
      </a>

      <div class="d-flex justify-content-start align-items-center mb-3">
        <Icon icon="carbon:collaborate" />
        <span class="ms-2">Collaboration <sup class="badge bg-danger">En cours</sup> </span>
      </div>

      <div class="d-flex justify-content-start align-items-center mb-3">
        <Icon icon="fa-solid:signature" />
        <span class="ms-2">Signature <sup class="badge bg-danger">En cours</sup></span>
      </div>

      <router-link
        to="/abonnement"
        class="d-flex justify-content-start align-items-center mb-3"
      >
        <Icon icon="uil:bill" />
        <span class="ms-2">Abonnement</span>
      </router-link>
    </div>
    <!-- <hr class="w-100" />
    <div class="ms-2">
      <div
        class="pointer d-flex justify-content-start align-items-center mb-3"
        @click="handleLogout"
      >
        <Icon icon="heroicons-outline:logout" />
        <span class="ms-2">Se déconnecter</span>
      </div>
    </div> -->
  </div>
</template>

<style>
.sidebar {
  min-width: 280px;
  height: 100vh;
  overflow-x: hidden;
  transition: 0.3s all ease-in-out;
}
.sidebar-btn-check,
.sidebar-btn-check:checked ~ div.sidebar {
  width: 0px !important;
  min-width: 0px !important;
  height: 0px !important;
  overflow: hidden;
  transition: 0.3s all ease-in-out;
}
.menu {
  color: #20356c;
}

.sidebar .router-link-active.router-link-exact-active {
  color: #dc3545;
}

.close-sidebar {
  display: none;
}
@media screen and (max-width: 772px) {
  .sidebar {
    position: fixed;
    z-index: 9999;
    background-color: #fff;
    box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.25);
  }
  .close-sidebar {
    display: block;
    position: absolute;
    right: 0;
  }
}
</style>
