<script>
import { Sidebar, Navbar } from "@/layout/components";
export default {
  components: {
    Sidebar,
    Navbar,
  },
};
</script>

<template>
  <div class="d-flex justify-content-start">
    <Sidebar />
    <div class="app_outer">
      <Navbar />
      <!-- <transition name="router-animation"> -->
      <main class="p-3">
        <router-view />
      </main>
      <!--       </transition> -->
    </div>
  </div>
</template>

<style>
.app_outer {
  width: 100%;
  background-color: #f7f8fc;
}
main {
  height: calc(100vh - 60px);
  overflow: scroll;
}

.router-animation-enter-active {
  animation: coming 0.2s;
  animation-delay: 0.1s;
  opacity: 0;
}
.router-animation-leave-active {
  animation: going 0.2s;
}

@keyframes going {
  from {
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    transform: translate3d(0, 4%, 0) scale(0.93);
    opacity: 0;
  }
}
@keyframes coming {
  from {
    transform: translate3d(0, 4%, 0) scale(0.93);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
}
</style>

