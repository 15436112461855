import Cookies from "js-cookie";

const TokenKey = "7ed90f8b-37a7-41e7-9b23-497f3745299c";

export function getToken() {
  return Cookies.get(TokenKey, { domain: ".opencontrat.com" });
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { domain: ".opencontrat.com" });
}

export function removeToken() {
  return Cookies.remove(TokenKey, { domain: ".opencontrat.com" });
}