<script setup>
import { computed } from "vue";
// eslint-disable-next-line no-undef
const props = defineProps({
  contents: { type: Object },
  currentIndex: { type: String },
});
// eslint-disable-next-line no-undef
defineEmits(["checkout"]);
const price = computed(() =>
  props.currentIndex === "month"
    ? props?.contents?.price
    : props.contents?.price * 12 * (1 - props.contents?.annual_reduction / 100)
);
const beneficiary_users = computed(() =>
  props?.currentIndex === "month"
    ? props?.contents?.beneficiary_users
    : props?.contents?.beneficiary_users * 12
);
const unlimited_free_contracts = computed(() =>
  props?.currentIndex === "month"
    ? props?.contents?.unlimited_free_contracts
    : props?.contents?.unlimited_free_contracts * 12
);
const contracts_number = computed(() =>
  props?.currentIndex === "month"
    ? props?.contents?.contracts_number
    : props?.contents?.contracts_number * 12
);
</script>

<template>
  <div id="cardCmp">
    <div v-show="contents?.name === 'Pro'" class="py-1 mx-auto recommanded">
      Recommandé
    </div>
    <h2 class="cardCmp__headTitle">{{ contents?.name }}</h2>
    <h4 class="cardCmp__subTitle">{{ contents?.description }}</h4>
    <div>
      <div class="prices">
        <h3 v-if="currentIndex === 'year'" class="inactivePrice">
          {{ props?.contents?.price * 12 }} FCFA
        </h3>
        <h2 class="prices__active">{{ price }} FCFA</h2>
      </div>
    </div>
    <h3 v-if="currentIndex === 'year'" class="cardCmp__littleTitle">
      /an/ sans engagement
    </h3>
    <h3 v-else class="cardCmp__littleTitle">/mois/ sans engagement</h3>
    <ul class="cardCmp__list">
      <li>
        <span v-if="beneficiary_users === 1"> 1 seul utilisateur</span>
        <span v-if="beneficiary_users > 1">
          {{ beneficiary_users }} utilisateurs</span
        >
        <span v-if="!contents?.beneficiary_users">
          Nombre illimité d'utilisateurs</span
        >
      </li>
      <li>
        <span v-if="unlimited_free_contracts === 1">
          Accès à {{ unlimited_free_contracts }} model de document gratuit
        </span>
        <span v-if="unlimited_free_contracts > 1">
          Accès à {{ unlimited_free_contracts }} modèles de documents gratuits
        </span>
        <span v-if="!unlimited_free_contracts">
          Accès aux modèles de documents gratuits en illimité</span
        >
      </li>
      <li>
        <span v-if="contracts_number === 1">
          Accès à {{ contracts_number }} document payant
        </span>
        <span v-if="contracts_number > 1">
          Accès à {{ contracts_number }} documents payants
        </span>
        <span v-else>
          Accès à l'ensemble des documents juridique en illimité</span
        >
      </li>
      <li v-if="contents?.electronic_signature">
        Signez électroniquement tous vos documents
      </li>
      <li v-if="contents?.collaboration">Collaborez avec vos utilisateurs</li>
      <li v-if="contents?.custom_contracts">
        Ajoutez vos propres modeles à utiliser par votre équipe
      </li>
      <li
        v-if="
          contents?.questions_mail ||
          contents?.questions_phone ||
          contents?.questions_chat
        "
      >
        Questions en illimité par {{ contents?.questions_mail ? "mail" : "" }}
        {{ contents?.questions_chat ? "chat" : "" }}
        {{ contents?.questions_phone ? "téléphone" : "" }}
      </li>
      <li v-if="contents?.support">
        Renseignement et assistance dans tous les domaines de droit
      </li>
    </ul>
    <div class="center">
      <button @click="$emit('checkout', contents)" class="cardCmp__btn">
        Démarrer
      </button>
    </div>
  </div>
</template>


<style scoped>
.recommanded {
  background: #ff0505;
  border-radius: 70px 70px 0 0;
  margin-top: -59px;
  margin-bottom: 40px;
  width: 75%;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}
#cardCmp {
  width: 300px;
  border: none;
  border-radius: 8px;
  background-color: white;
  box-shadow: -1px -1px 9px 0px rgba(0, 0, 0, 0.48);
  padding: 30px 5px 5px 5px;
  margin-inline: 8px;
  margin-bottom: 30px;
  position: relative;
}

.cardCmp__headTitle {
  font-weight: 400;
  font-size: 25px;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: #2696be;
  text-decoration-thickness: 5px;
}

.cardCmp__divider {
  width: 100;
}

.cardCmp__subTitle {
  width: 300px;
  font-weight: 300;
  font-style: italic;
  text-align: center;
  font-size: 13px;
  margin: 18px 0 10px 0;
}

.prices__active {
  text-align: center;
  color: #ff0000;
  font-weight: 800;
  font-size: 22px;
}

.cardCmp__littleTitle {
  font-size: 12px;
  text-align: center;
  font-weight: 300;
  font-style: italic;
}

.cardCmp__list {
  margin: 30px 0 60px 0;
}

.cardCmp__list li {
  margin: 20px 0 0 0;
  color: #6b6464;
  font-size: 14px;
}

.cardCmp__list li::marker {
  color: #ffd800;
  width: 50px;
}

.cardCmp__btn {
  position: absolute;
  bottom: 0px;
  margin-bottom: 10px;
  padding: 10px 16px;
  border: none;
  color: #fcfcfc;
  font-weight: bold;
  font-size: 14;
  background-color: #43c3df;
  border-radius: 38px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inactivePrice {
  font-size: 12px;
  color: grey;
  text-decoration: line-through;
  margin-right: 8px;
}

.prices {
  display: flex;
  justify-content: center;
}
</style>
