<script setup>
import { ref } from "vue";
// eslint-disable-next-line no-undef
defineEmits(["paginate"]);
// eslint-disable-next-line no-undef
const props = defineProps({
  perPage: {
    required: true,
    type: Number,
    default: 1,
  },
  currentPage: {
    required: true,
    type: Number,
    default: 1,
  },
  total: {
    required: true,
    type: Number,
    default: 1,
  },
});

const totalPage = ref();
const from = ref();
const to = ref();
totalPage.value = Math.ceil(props.total / props.perPage);
from.value = props.perPage * props.currentPage;
to.value = from.value + props.perPage;
</script>
<template>
  <div
    v-if="!totalPage == 1"
    class="d-flex justify-content-between align-items-center m-3"
  >
    <ul class="pagination">
      <li>
        <p aria-label="Previous">
          <span aria-hidden="true">«</span>
        </p>
      </li>
      <template v-for="(item, index) in totalPage" :key="index">
        <li :class="item == currentPage ? 'active' : ''">
          <p @click="$emit('paginate', item)" class="pointer">
            {{ item }}
          </p>
        </li>
      </template>
      <li>
        <p aria-label="Next">
          <span aria-hidden="true">»</span>
        </p>
      </li>
    </ul>
    <div class="">
      <em>Affichage de {{ from }} à {{ to }} (sur {{ total }} éléments)</em>
    </div>
  </div>
</template>


<style>
</style>