<script setup>
import { reactive, ref } from "vue";
import { updateProfile, updatePassword } from "@/http/auth";
import { ElMessage } from "element-plus";
// eslint-disable-next-line no-undef
const props = defineProps({
  user: {
    type: Object,
  },
});
const ruleFormRef = ref();
const ruleForm = reactive({
  password: "",
  new_password: "",
  new_password_confirmation: "",
});

const ruleFormProfileRef = ref();
const credentialsForm = reactive({
  email: props?.user?.email,
  phone: props?.user?.phone,
  name: props?.user?.name,
});

const passwordLoading = ref(false);
const accountLoading = ref(false);

const validatePass = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("Veuillez entrer le mot de passe"));
  } else if (value.length < 6) {
    callback(new Error("Veuillez entrer au moins 6 caracteres"));
  } else {
    if (ruleForm.new_password_confirmation !== "") {
      ruleFormRef.value.validateField("new_password_confirmation");
    }
    callback();
  }
};
const validatePass2 = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("Veuillez entrer à nouveau le mot de passe"));
  } else if (value !== ruleForm.new_password) {
    callback(new Error("Les deux entrées ne correspondent pas!"));
  } else {
    callback();
  }
};

const rules = reactive({
  password: [{ required: true, message: "Champs requis", trigger: "blur" }],
  new_password: [{ validator: validatePass, trigger: "blur" }],
  new_password_confirmation: [{ validator: validatePass2, trigger: "blur" }],
});

const profileRules = reactive({
  email: [{ required: true, message: "Champs requis", trigger: "blur" }],
  phone: [{ required: true, message: "Champs requis", trigger: "blur" }],
  name: [{ required: true, message: "Champs requis", trigger: "blur" }],
});

const update_pwd = async () => {
  if (!ruleFormRef.value) return;
  await ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      passwordLoading.value = true;
      await updatePassword(ruleForm)
        .then(() => {
          ruleFormRef.value.resetFields();
          passwordLoading.value = false;
          ElMessage({
            message: "Mot de passe changé avec succès",
            type: "success",
            duration: 5 * 1000,
          });
        })
        .catch(() => {
          ElMessage({
            message: "Mot de passe invalide",
            type: "error",
          });
          passwordLoading.value = false;
        });
    }
  });
};

const update_profile = async () => {
  if (!ruleFormProfileRef.value) return;
  await ruleFormProfileRef.value.validate(async (valid) => {
    if (valid) {
      accountLoading.value = true;
      await updateProfile(credentialsForm)
        .then(() => {
          accountLoading.value = false;
          ruleFormProfileRef.value.resetFields();
          ElMessage({
            message: "Compte mis à jour avec succès",
            type: "success",
            duration: 5 * 1000,
          });
          location.reload();
        })
        .catch(() => {
          accountLoading.value = false;
        });
    }
  });
};
</script>

<template>
  <div>
    <el-form
      ref="ruleFormRef"
      label-position="top"
      label-width="150px"
      :model="ruleForm"
      status-icon
      :rules="rules"
    >
      <el-form-item label="Ancien mot de passe" prop="password">
        <el-input
          v-model="ruleForm.password"
          type="password"
          placeholder="Ancien mot de passe"
          size="default"
        />
      </el-form-item>
      <el-form-item label="Nouveau mot de passe" prop="new_password">
        <el-input
          v-model="ruleForm.new_password"
          type="password"
          placeholder="Nouveau mot de passe"
          size="default"
        />
      </el-form-item>
      <el-form-item label="Confirmation" prop="new_password_confirmation">
        <el-input
          v-model="ruleForm.new_password_confirmation"
          type="password"
          placeholder="Confirmation du nouveau mot de passe"
          size="default"
        />
      </el-form-item>
      <el-form-item>
        <el-button :loading="passwordLoading" id="buttons" @click="update_pwd()"
          >Changer mot de passe</el-button
        >
      </el-form-item>
    </el-form>

    <hr />

    <el-form
      label-width="170px"
      :model="credentialsForm"
      label-position="left"
      :rules="profileRules"
      ref="ruleFormProfileRef"
    >
      <el-form-item label="Adresse mail" prop="email">
        <el-input
          v-model="credentialsForm.email"
          :placeholder="credentialsForm.email"
          type="text"
          size="default"
        />
      </el-form-item>
      <el-form-item label="Numéro de téléphone" prop="phone">
        <el-input
          v-model="credentialsForm.phone"
          :placeholder="credentialsForm.phone"
          type="text"
          size="default"
        />
      </el-form-item>
      <el-form-item label="Nom" prop="name">
        <el-input
          v-model="credentialsForm.name"
          :placeholder="credentialsForm.name"
          type="text"
          size="default"
        />
      </el-form-item>
      <el-button
        :loading="accountLoading"
        id="buttons"
        @click="update_profile()"
        >Mettre à jour</el-button
      >
    </el-form>
  </div>
</template>

<style scoped>
#buttons {
  color: #2696be;
  border-color: #2696be;
}
</style>