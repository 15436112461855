import { createRouter, createWebHashHistory } from "vue-router";

import Layout from "@/layout/index";
import Profil from "@/views/profil/index.vue";
export const constantRoutes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        component: () => import("@/views/contrats/index.vue"),
        name: "Contrats",
      },
      {
        path: "signature",
        component: () => import("@/views/contrats/index.vue"),
        name: "signature",
      },
      {
        path: "collaboration",
        component: () => import("@/views/contrats/index.vue"),
        name: "Collaboration",
      },
      {
        path: "abonnement",
        component: () => import("@/views/subscription.vue"),
        name: "abonnement",
      },
      {
        path: "profil",
        component: Profil,
        name: "Profil",
      },
    ],
  },
  {
    path: "/401",
    name: "Unauthorized",
    component: () => import("@/views/error/401.vue"),
    meta: { isAuthenticated: false },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/error/404.vue"),
  },
];

export const asyncRoutes = [];

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
});

export function resetRouter() {
  const newRouter = router();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
