<template>
  <div class="text-primary">
    <Icon icon="eos-icons:loading" />
    <small> Patientez s'il vous plait</small>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
export default {
  components: {
    Icon,
  },
};
</script>

<style>
</style>