import router from "./router";
import store from "./store/index";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/helpers/auth"; // get token from cookie
import getPageTitle from "@/helpers/get-page-title";

NProgress.configure({ showSpinner: true }); // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken();

  if (hasToken) {
    if (to.path === "/401") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
      NProgress.done();
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const user_roles = store.getters["user/roles"];
      const hasRoles = user_roles && user_roles.length > 0;
      if (hasRoles) {
        next();
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const { role } = await store.dispatch("user/getInfo");

          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch(
            "permission/generateRoutes",
            role
          );

          // dynamically add accessible routes
          accessRoutes.forEach((element) => {
            router.addRoute(element);
          });

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true });
        } catch (error) {
          console.error(error || "Has Error");
          await store.dispatch("user/getInfo").catch(async () => {
            // remove token and go to login page to re-login
            await store.dispatch("user/resetToken");
          });
          //
          next('/401');
          NProgress.done();
        }
      }
    }
  } else {
    if (to?.meta?.isAuthenticated === false) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next('/401');
      NProgress.done();
    }
  } 
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
