import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  TextInput,
  PasswordInput,
  Select,
  Button,
  SearchInput,
  CardContrat,
  ConfirmationModal,
  Loader,
  PanThumb,
  NumberInput,
  Pagination,
} from "./components/index";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "./styles/index.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "maz-ui/css/main.css";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import "./permission.js";
createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus, { size: "small" })
  .component("QuillEditor", QuillEditor)
  .component("TextInput", TextInput)
  .component("PasswordInput", PasswordInput)
  .component("Button", Button)
  .component("Select", Select)
  .component("SearchInput", SearchInput)
  .component("CardContrat", CardContrat)
  .component("ConfirmationModal", ConfirmationModal)
  .component("MazPhoneNumberInput", MazPhoneNumberInput)
  .component("PanThumb", PanThumb)
  .component("Loader", Loader)
  .component("NumberInput", NumberInput)
  .component("Pagination", Pagination)
  .mount("#app");
